import { CDN_BASE_URL } from "../config";

const initialState = {
  loader: {
    actions: [],
    // refreshing: [],
  },
  error: {
    dialog: {
      open: false,
      code: null,
    },
    form: {},
  },
  flow: {
    step: 0,
    payment: {
      method: "",
      data: {},
    },
  },
  theme: {},
};

const uiReducer = (state = initialState, { type, payload }) => {
  const { loader, error, flow } = state;
  const { actions } = loader;
  const { form } = error;
  const { step, payment } = flow;
  const checkPayloadWithCurrentAction = (payload, currentAction) => {
    if (payload?.action?.name ?? "" === currentAction.name) return true;
    // else if (payload?.action?.params ?? "" === currentAction.params) return true;

    return false;
  };

  switch (type) {
    case "UI/SET_UI_INITIAL_STATE":
      return { ...initialState };

    case "UI/START_ACTION":
      return {
        ...state,
        loader: {
          ...(loader ?? {}),
          actions: [...(actions ?? []), payload.action],
        },
      };
    case "UI/STOP_ACTION":
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter(
            (remainingAction) =>
              !checkPayloadWithCurrentAction(payload, remainingAction)
          ),
        },
      };
    default:
      return state;

    case "UI/NEXT_STEP":
      return {
        ...state,
        flow: {
          ...flow,
          step: step + 1,
        },
      };

    case "UI/PREVIOUS_STEP":
      return {
        ...state,
        flow: {
          ...flow,
          step: step - 1,
        },
      };

    case "UI/SET_STEP":
      return {
        ...state,
        flow: {
          ...flow,
          step: payload,
        },
      };
    case "UI/ERROR_OPEN_DIALOG":
      return {
        ...state,
        error: {
          ...error,
          dialog: {
            open: true,
            code: payload.code,
          },
        },
      };
    case "UI/ERROR_CLOSE_DIALOG":
      return {
        ...state,
        error: {
          ...error,
          dialog: {
            open: false,
            code: null,
          },
        },
      };

    case "UI/ADD_ERROR_MESSAGE_TO_NAMESPACE":
      const [namespace, errorMessage] = Object.entries(payload)[0];
      const isNamespaceNotEmpty =
        state.error.form[namespace] && state.error.form[namespace].length > 0;
      const isMessageRepeated =
        isNamespaceNotEmpty &&
        state.error.form[namespace].includes(errorMessage);
      if (!isMessageRepeated)
        return {
          ...state,
          error: {
            ...error,
            form: {
              ...form,
              [namespace]: [...(form?.[namespace] ?? []), errorMessage],
            },
          },
        };
      return state;

    case "UI/ERROR_CLEAR_FORM":
      return {
        ...state,
        error: {
          ...error,
          form: {},
        },
      };

    case "UI/DEFINE_PAYMENT_DATA":
      const { method, ...remainingData } = payload;
      const updateData = {
        method,
        data: {},
      };
      if (method === "mb") {
        const { amount, entity, reference, last_updated } = remainingData;
        updateData.data = {
          amount: amount ?? "0",
          entity: entity ?? "nao encontrado",
          reference: reference ?? "nao encontrado",
          last_updated: last_updated ?? "nao encontrado",
        };
      }
      return {
        ...state,
        flow: {
          ...flow,
          payment: {
            ...payment,
            ...updateData,
          },
        },
      };

    case "UI/SET_THEME":
      const { ...theme } = payload;
      const { images } = theme;
      const imagesWithBaseUrl = {};
      for (const key in images) {
        if (images.hasOwnProperty(key)) {
          imagesWithBaseUrl[key] = `${CDN_BASE_URL}${images[key]}`;
        }
      }
      return {
        ...state,
        theme: {
          ...theme,
          images: {
            ...imagesWithBaseUrl,
          },
        },
      };
  }
};

export default uiReducer;
