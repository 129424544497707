import { MAIN_COLOR, PROXY_PATH, SECONDARY_COLOR } from "../../config";
import { getItemLocalStorage } from "../../utils/helper";
import { Row, Col, Form, Nav, Button, Modal } from "react-bootstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import { UIactions } from "../../actions/index";
import LogoMBWay from "../../assets/images/logommway.png";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import RightSidebar from "./RightSidebar";
import FormRadio from "./shared/FormRadio";
import { getDataByNamespace, getSpecsByNamespace } from "./utils";
import { LOCAL_STORAGE_KEYS as LOCAL_STORAGE } from "../../utils/constants";

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const quoteProperties = useSelector((state) => state.quote.properties);

  const quoteId = useSelector((state) => state.quote.id);

  const insuranceProduct = useSelector((state) => state.insuranceProduct);
  const customData = useSelector((state) => state.customData);
  const user = useSelector((state) => state.user);
  const userDoesntHaveID =
    user.id === null || user.id === undefined || user.id === "";

  const [iban, setIban] = useState("");
  const [show, setShow] = useState(false);
  const [quote_id, setQuoteid] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [isLoader, setisLoader] = useState(false);
  const [paymentlist, setPaymentlist] = useState([]);
  const [paid_in_store, setPaidInStore] = useState(false);
  const [mbway_number, setMbwaynumber] = useState("");
  const [payment_method, setPaymentmethod] = useState("");
  const [globalDisclaimer, setGlobalDisclaimer] = useState([]);
  const [productDisclaimer, setProductDisclaimer] = useState("");
  const [sepa_debit_authorized, setSepaDebitAuthorized] = useState("");
  const [sepa_first_payment_aknowledged, setSepaFirstPaymentAknowledged] =
    useState(false);
  const [payment_frequency_name, setPaymentFrequencyName] = useState("");

  const usertype = getItemLocalStorage(LOCAL_STORAGE.TYPE);
  const fingerprint = getItemLocalStorage(LOCAL_STORAGE.FINGERPRINT);
  const access_token = getItemLocalStorage(LOCAL_STORAGE.ACCESS_TOKEN);

  const sidebarProps = {
    usertype: usertype,
    payment_frequency_name: payment_frequency_name,
    rate_base: getDataByNamespace("rate_base", quoteProperties),
    productname: insuranceProduct.name,
    documents: insuranceProduct.documents.at_offer,
    policyHolderName: customData.policyholder_name,
  };

  var headers;
  headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "X-FINGERPRINT": fingerprint,
  };

  if (usertype === "guest") {
    headers = {
      "Content-Type": "application/json",
      "X-FINGERPRINT": fingerprint,
    };
  }

  useEffect(() => {
    const paymentFrequencyLabel = customData.payment_frequency.find(
      (property) =>
        property.data ===
        quoteProperties.find(
          (property) => property.namespace === "payment_frequency"
        ).data
    ).label;

    setPaymentFrequencyName(paymentFrequencyLabel);
    setPaymentlist(
      quoteProperties.find(
        (property) => property.namespace === "payment_method"
      ).options
    );

    setisLoader(true);
    setTimeout(function () {
      setisLoader(false);
    }, 1500);

    setGlobalDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "global")
    );
    setProductDisclaimer(
      insuranceProduct.legal_disclaimers.find((x) => x.type === "by_product")
    );

    setQuoteid(quoteId);
  }, []);

  const handleClose = () => {
    setShow(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(UIactions.clearErrorForm());
    setisLoader(true);
    var formData = "";
    if (payment_method === "mbway") {
      formData = JSON.stringify({
        properties: [
          {
            namespace: "payment_method",
            data: payment_method,
          },
          {
            namespace: "mbway_number",
            data: mbway_number.replaceAll(" ", ""),
          },
          {
            namespace: "paid_in_store",
            data: paid_in_store === true ? "true" : "false",
          },
        ],
      });
    } else if (payment_method === "sepa_debit") {
      formData = JSON.stringify({
        properties: [
          {
            namespace: "payment_method",
            data: payment_method,
          },
          {
            namespace: "paid_in_store",
            data: paid_in_store === true ? "true" : "false",
          },
          {
            namespace: "iban",
            data: iban,
          },
          {
            namespace: "sepa_debit_authorized",
            data: sepa_debit_authorized === true ? "true" : "false",
          },
          {
            namespace: "sepa_first_payment_aknowledged",
            data: sepa_first_payment_aknowledged === true ? "true" : "false",
          },
        ],
      });
    } else {
      formData = JSON.stringify({
        properties: [
          { namespace: "payment_method", data: payment_method },
          {
            namespace: "paid_in_store",
            data: paid_in_store === true ? "true" : "false",
          },
        ],
      });
    }

    axios
      .post(`${PROXY_PATH}/quotes/${quote_id}/update-properties`, formData, {
        headers: headers,
      })
      .then((res) => {
        setisLoader(false);
        var simulated = res.data.simulated;
        if (simulated.error_response !== undefined) {
          toast.error(simulated.error_response.text);
        } else {
          if (res.data.text !== undefined) {
            toast.error(res.data.text);
          } else {
            submitContract();
          }
        }
      })
      .catch((err) => {
        setisLoader(false);
      });
  };

  const submitContract = () => {
    setisLoader(true);

    const payload = {};
    if (userDoesntHaveID) payload.user = { name: user.name, email: user.email };
    else payload.user_id = user.id;

    var formData = JSON.stringify(payload);
    axios
      .post(`${PROXY_PATH}/quotes/${quote_id}/submit-contract`, formData, {
        headers: headers,
      })
      .then((res) => {
        setisLoader(false);
        if (res.data.text !== undefined) {
          toast.error(res.data.text);
        } else {
          const firstPayment = res?.data?.customized?.payments?.[0];
          const isFirstPaymentMB =
            (firstPayment && firstPayment.multibanco) || false;
          if (isFirstPaymentMB) {
            const updated = res.data.updated;
            const { amount, entity, reference } = firstPayment.multibanco;
            dispatch(
              UIactions.definePaymentData(
                "mb",
                { amount, entity, reference },
                updated
              )
            );
          }

          if (payment_method === "cc") {
            const redirectURL =
              res?.data?.customized?.payments?.[0]?.redirect_url ?? "";
            if (redirectURL) {
              if (window.top === window.self) {
                // Top level window
                window.location.href = redirectURL;
              } else {
                // Not top level. An iframe, popup or something
                const message = JSON.stringify({
                  message: "openPaymentGW",
                  redirect_url: redirectURL,
                });
                window.parent.postMessage(message, "*");
              }
            } else {
              navigate("/error");
            }
          } else if (payment_method === "mbway") {
            setShow(true);
            updateCounter(percentage);
            paymentLoop(res.data.id);
          } else if (payment_method === "mb") {
            navigate("/success?pm=mb");
          } else {
            navigate("/success");
          }
        }
      })
      .catch((err) => {
        setisLoader(false);
        if (err.response !== undefined) {
          if (err.response.data.text !== undefined) {
            toast.error(err.response.data.text);
          }
        }
      });
  };

  const updateCounter = (counter) => {
    setTimeout(function () {
      var newcounter = counter + 1;
      if (newcounter > 300) {
        return false;
      } else if (newcounter == 300) {
        clearInterval(paymentLoop);
      }
      setPercentage(newcounter);
      updateCounter(newcounter);
      localStorage.setItem("checkcounter", newcounter);
    }, 1000);
  };

  var paymentInterval;
  const paymentLoop = (policy_id) => {
    paymentInterval = setInterval(() => {
      setisLoader(false);
      checkPaymentStatus(policy_id);
    }, 3000);
  };

  const checkPaymentStatus = (policy_id) => {
    axios
      .get(`${PROXY_PATH}/policies/${policy_id}/contributions`, {
        headers: headers,
      })
      .then((res) => {
        if (localStorage.getItem("checkcounter") >= 300) {
          clearInterval(paymentInterval);
          if (res.data.elements[0].state === "pending") {
            navigate("/error");
          } else if (res.data.elements[0].state === "paid") {
            clearInterval(paymentInterval);
            navigate("/success");
          }
        } else if (res.data.elements[0].state === "paid") {
          clearInterval(paymentInterval);
          navigate("/success");
        }
      })
      .catch((error) => {
        clearInterval(paymentLoop);
        if (error.response === undefined) {
          if (localStorage.getItem("checkcounter") >= 300) {
            navigate("/error");
          }
        }
      });
  };

  const isButtonActive = () => {
    if (!paymentlist || paymentlist.length === 0) {
      return false;
    }
    if (payment_method === "") {
      return false;
    }
    if (
      payment_method === "sepa_debit" &&
      (!sepa_debit_authorized || !sepa_first_payment_aknowledged)
    ) {
      return false;
    }
    if (payment_method === "store" && !paid_in_store) {
      return false;
    }
    if (payment_method === "mbway" && !mbway_number) {
      return false;
    }
    if (payment_method === "sepa_debit" && !iban) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      <ToastContainer theme="dark" autoClose={10000} />
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
      <Row className="text-start m-0 simulation-div align-items-start">
        <Col md={9} className="leftbar">
          {window.innerWidth < 768 && (
            <>
              <h3
                className="left-content-heading text-center d-block mt-2 mb-4"
                style={{ color: SECONDARY_COLOR }}
              >
                Pagamento
              </h3>
              <Nav
                as="ul"
                className="mobile-bar step-four align-items-center justify-content-between"
              >
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
                <Nav.Item as="li" className="active"></Nav.Item>
              </Nav>
            </>
          )}
          <div className="left-content-box active">
            <h3
              className="left-content-heading m-0"
              style={{ color: SECONDARY_COLOR }}
            >
              <i className="bi bi-check-circle-fill"></i> Simulação
            </h3>
            <a
              type="button"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                dispatch(UIactions.setStep(0));
              }}
              className="edit_btn"
            >
              <i className="bi bi-pencil"></i>Editar
            </a>
          </div>
          <div className="left-content-box active">
            <h3
              className="left-content-heading m-0"
              style={{ color: SECONDARY_COLOR }}
            >
              <i className="bi bi-check-circle-fill"></i> Dados de{" "}
              {usertype === "lead" ? "Validação" : "Gerais"}
            </h3>
            <a
              type="button"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                dispatch(UIactions.setStep(1));
              }}
              className="edit_btn"
            >
              <i className="bi bi-pencil"></i>Editar
            </a>
          </div>
          <div className="left-content-box active">
            <h3
              className="left-content-heading m-0"
              style={{ color: SECONDARY_COLOR }}
            >
              <i className="bi bi-check-circle-fill"></i> Dados do Beneficiário
            </h3>
            <a
              type="button"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                dispatch(UIactions.previousStep());
              }}
              className="edit_btn"
            >
              <i className="bi bi-pencil"></i>Editar
            </a>
          </div>
          <div className="left-content-box">
            <h3
              className="left-content-heading"
              style={{ color: SECONDARY_COLOR }}
            >
              <span
                className="counter-box d-inline-flex align-items-center justify-content-center"
                style={{ color: SECONDARY_COLOR, borderColor: SECONDARY_COLOR }}
              >
                4
              </span>{" "}
              Método de Pagamento
            </h3>
            <Form
              method="post"
              className="payment-form"
              onSubmit={handleSubmit}
            >
              <FormRadio
                specs={getSpecsByNamespace("payment_method", quoteProperties)}
                value={payment_method}
                onChange={(event) => setPaymentmethod(event.target.value)}
              />
              <Form.Label
                className={`payment-form-label cc_div d-${
                  payment_method === "cc" || payment_method === "sepa_debit"
                    ? "block"
                    : "none"
                }`}
              ></Form.Label>
              <Row
                className={`d-${payment_method === "mbway" ? "block" : "none"}`}
              >
                <Form.Label className={`mb-3 payment-form-label`}>
                  Nesta via de pagamento terá de verificar as notificações na
                  app MB Way do n° aderente, para autorizar o pagamento e
                  concluir o mesmo
                </Form.Label>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Form.Group className="mb-4">
                    <Form.Label className="payment-form-label">
                      Número de Aderente
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Inserir..."
                      name="mbway_number"
                      value={mbway_number}
                      onChange={(e) => setMbwaynumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row
                className={`d-${
                  payment_method === "sepa_debit" ? "block" : "none"
                }`}
              >
                <Form.Label className="value-label m-0">
                  O débito direto será
                  <span className="paymentfrqy"> {payment_frequency_name}</span>
                </Form.Label>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Form.Group className="mb-4">
                    <Form.Label className="payment-form-label">IBAN</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Inserir..."
                      name="iban"
                      value={iban}
                      onChange={(e) => setIban(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label
                className={`mb-3 payment-form-label d-${
                  payment_method === "cc" ? "block" : "none"
                }`}
              >
                Nesta via de pagamento será redireccionado para uma página de
                pagamento
              </Form.Label>

              {payment_method === "sepa_debit" ? (
                <>
                  <Col>
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        name="sepa_debit_authorized"
                        id="sepa_debit_authorized_uid"
                        label={`${
                          usertype === "seller"
                            ? "O cliente autoriza"
                            : "Autorizo"
                        } a Habit a recolher e utilizar os dados desta conta bancária para pagamento do prémio do seguro através de débito direto`}
                        value={sepa_debit_authorized}
                        onChange={(e) => {
                          setSepaDebitAuthorized(!sepa_debit_authorized);
                        }}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        name="sepa_first_payment_aknowledged"
                        id="sepa_first_payment_aknowledged_uid"
                        label={`${
                          usertype === "seller"
                            ? "O cliente foi informado"
                            : "Compreendo"
                        } que a primeira prestação terá de ser paga através de Referência Multibanco para que o contrato seja ativado`}
                        value={sepa_first_payment_aknowledged}
                        onChange={(e) => {
                          setSepaFirstPaymentAknowledged(
                            !sepa_first_payment_aknowledged
                          );
                        }}
                      ></Form.Check>
                    </Form.Group>
                  </Col>
                </>
              ) : null}

              {payment_method === "store" ? (
                <Col>
                  <Form.Group className="form-group">
                    <Form.Check
                      type="checkbox"
                      name="paid_in_store"
                      id="paid_in_store_uid"
                      label="Confirmo que o cliente já efetuou o pagamento em loja"
                      value={paid_in_store}
                      onChange={(e) => {
                        setPaidInStore(!paid_in_store);
                      }}
                    ></Form.Check>
                  </Form.Group>
                </Col>
              ) : null}

              <Form.Label
                className={`mb-3 payment-form-label d-${
                  payment_method === "mb" ? "block" : "none"
                }`}
              >
                Irá receber por email e SMS a referência multibanco, para
                efetuar o pagamento numa caixa de Multibanco ou em Homebanking
              </Form.Label>

              {window.innerWidth >= 768 ? (
                <Button
                  type="submit"
                  className={`simulation-button ${
                    isButtonActive() ? "" : "disabled"
                  }`}
                  variant="warning"
                  style={{ backgroundColor: MAIN_COLOR }}
                >
                  CONFIRMAR ADESÃO{" "}
                </Button>
              ) : (
                ""
              )}
            </Form>
          </div>

          <div className="d-block footer-div">
            <div className="footer-text">
              {globalDisclaimer ? (
                <p className="m-0">{globalDisclaimer.text}</p>
              ) : (
                ""
              )}
              {productDisclaimer ? (
                <p className="m-0">{productDisclaimer.text}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
        <Col md={3} className="right-sidebar">
          <RightSidebar
            props={sidebarProps}
            previousFunction={() => dispatch(UIactions.previousStep())}
            submitFunction={handleSubmit}
            canSubmit={isButtonActive()}
          />
        </Col>
      </Row>
      <div className="model-container">
        <Modal
          id="mbwayModel"
          show={show}
          onHide={handleClose}
          backdrop="static"
        >
          <Modal.Body className="text-center">
            <div className="body-logo">
              <img src={LogoMBWay} alt="img" style={{ width: "115px" }} />
            </div>
            <h2>Confirmar Pagamento</h2>
            <p>Por favor, confirme o pagamento na sua aplicação MB Way!</p>
            <p className="time-heading">Atenção expira em 5 min...</p>
            <CircularProgressbar value={Math.ceil(percentage / 3)} text={``} />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

export default Payment;
