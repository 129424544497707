import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { SECONDARY_COLOR } from "../config";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { LIGHT_BLUE } from "../color";
import {
  clearLocalStorage,
  formatTextWithLineBreaksToJSX,
  getItemLocalStorage,
} from "../utils/helper";
import {
  LOCAL_STORAGE_KEYS as LOCAL_STORAGE,
  SUCCESS_PAGE_PROPS as PROPS,
} from "../utils/constants";

const SimulationSuccess = () => {
  const { flow: userType } = useSelector((state) => state.distributionDetails);
  const UI = useSelector((state) => state.UI);
  const isLoader = UI?.loader?.actions?.length > 0 ?? false;

  const { image, success_heading, success_text } =
    PROPS[userType] ?? PROPS.default;

  const { payment } = UI?.flow ?? {};
  const isMB = payment?.method === "mb";

  const redirectURI = getItemLocalStorage(LOCAL_STORAGE.REDIRECT_URI) ?? "/";
  const formatDateToReport = (date) => {
    let d = new Date(date).getDate();
    let y = new Date(date).getFullYear();
    let m = new Date(date).getMonth() + 1;
    return `${d}-${m}-${y}`;
  };

  const { amount, entity, reference, last_updated } = payment?.data ?? {};

  return (
    <>
      {isLoader === true && (
        <ThreeDots
          height="90"
          width="90"
          radius="9"
          color="#FDBE14"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass="ajax-loader"
          visible={true}
        />
      )}
      <Col
        xxl={6}
        xl={6}
        lg={6}
        md={7}
        sm={10}
        xs={12}
        className="success-screen d-flex flex-column align-items-center justify-content-center m-auto pt-4"
      >
        <div className="success-img mb-4">
          <img src={image} alt="" />
        </div>
        <h4
          className="success-heading text-center mb-4"
          style={{ color: SECONDARY_COLOR }}
        >
          {success_heading}
        </h4>
        {isMB ? (
          <>
            <p className="success-text text-center mb-4">
              Poderá efetuar o pagamento ate ao dia{" "}
              {formatDateToReport(last_updated)}
              <br />
              As referências abaixo são apenas para a primeira
              mensalidade/recibo:
            </p>
            <div className="text-start shadow rounded p-4 mb-5">
              <div className="d-flex mb-2">
                <div className="me-2">Entidade: </div>
                <div className="fw-bold">{entity}</div>
              </div>
              <div className="d-flex mb-2">
                <div className="me-2">Referência: </div>
                <div className="fw-bold">{reference}</div>
              </div>
              <div className="d-flex mb-2">
                <div className="me-2">Valor: </div>
                <div className="fw-bold">{amount} €</div>
              </div>
            </div>
          </>
        ) : (
          <p className="success-text text-center mb-4">
            {formatTextWithLineBreaksToJSX(success_text)}
          </p>
        )}

        {userType !== "user" && (
          <Link
            to={redirectURI}
            className="btn text-white home-btn d-xxl-block d-xl-block d-md-block d-sm-none d-none"
            style={{ background: LIGHT_BLUE }}
            onClick={clearLocalStorage}
          >
            OK
          </Link>
        )}
      </Col>
    </>
  );
};

export default SimulationSuccess;
